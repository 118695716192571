import Image from 'next/image';
import { useRouter } from 'next/router';
import { useEffect, useRef, useState } from 'react';
import {
  CheckboxItemList,
  HitsStats,
  Pagination,
  SearchkitManager,
  SearchkitProvider,
  SelectedFilters,
  SortingSelector,
  TermQuery,
  Toggle
} from 'searchkit';

import {
  ProductFilter,
  ProductFilterModal,
  ProductParentCategories
} from '@/components/atomic/atoms';
import { Accordion, ProductCard } from '@/components/atomic/molecules';
import { BottomModal, FilterModal } from '@/components/atomic/nuclei';
import {
  CategoryMobileHeaderOrganism,
  ProductListOrganism
} from '@/components/atomic/organism';
import { staticMediaStoreBaseURL } from '@/config/common';
import searchKitConfig from '@/config/searchKit';
import { getSerializedRichText } from '@/helpers/editor';
import { getCategoryListingPageH1Title } from '@/services/categories.service';

const CategoryMobileLayoutTemplate = ({
  categories,
  category,
  categorySeoDetails,
  categorySlug,
  initialProducts,
  parentCategories,
  parentCategoryName,
  parentSlug,
  priceRange,
  productsCount,
  showProductSubCategory,
  subCategories,
  subCategorySlug
}) => {
  // TODO split the component
  const router = useRouter();
  const [showModal, setShowModal] = useState(false);
  const [isSorting, setIsSorting] = useState(false);
  const [isFilter, setIsFilter] = useState(false);
  const [CloseAnimation, setCloseAnimation] = useState(true);
  const [currentUrl, setCurrentUrl] = useState();
  const [appliedFilters, setAppliedFilters] = useState([]);
  const [openTab, setOpenTab] = useState(1);

  const productListingRef = useRef(null);

  const {
    metaTitle,
    metaDescription,
    ogTitle,
    ogDescription,
    ogImage,
    description,
    faq
  } = categorySeoDetails;

  useEffect(() => {
    productListingRef.current.scrollIntoView();
  }, []);

  let searchKitTermQueries = [];
  if (subCategorySlug) {
    searchKitTermQueries = [TermQuery('assignedToCategories', subCategorySlug)];
  } else if (categorySlug) {
    searchKitTermQueries = [TermQuery('assignedToCategories', categorySlug)];
  } else {
    searchKitTermQueries = [TermQuery('assignedToCategories', parentSlug)];
  }

  const searchkit = new SearchkitManager(
    searchKitConfig.searchKitProductListAPIEndPoint
  );
  searchkit.addDefaultQuery((query) =>
    query.addQuery({
      bool: {
        should: searchKitTermQueries
      }
    })
  );
  searchkit.setQueryProcessor((plainQueryObject) => plainQueryObject);

  useEffect(() => {
    document.body.style.overflow = 'unset';
    setShowModal(false);
  }, [parentSlug]);

  const upIcon = '/icons/up-arrow-gray.svg';

  const handleModal = () => {
    const isModalVisible = showModal;
    if (!isModalVisible) {
      document.body.style.overflow = 'hidden';
    }
    if (isModalVisible) {
      document.body.style.overflow = 'unset';
    }

    setCloseAnimation(!isModalVisible);
    setTimeout(() => {
      setShowModal(!isModalVisible);
    }, 200);
  };

  const handleSortModal = () => {
    setIsSorting(true);
    window.scrollTo(0, 0);
  };

  const handleFilterModal = () => setIsFilter(true);

  const renderProductsCount = (props) => (
    <div className='flex items-center my-2.5 px-5'>
      <h1 className='inline-block truncate pr-1'>
        {
          <span className='font-medium text-sm font-Montserrat text-nero leading-5'>
            {getCategoryListingPageH1Title(category)}
          </span>
        }
      </h1>
      <span className='flex-shrink-0 font-normal text-sm font-Montserrat text-dim-gray leading-5'>
        {' - '}
        {props.hitsCount}
        {' products'}
      </span>
    </div>
  );

  const renderPagination = (props) => {
    const handleClick = () => {
      if (!props.disabled) {
        props.onClick();
        window.scrollTo(0, 0);
      }
    };

    if (props.label === 'Previous') {
      return (
        <div
          onClick={handleClick}
          className={`${
            props.disabled
              ? 'text-dim-gray'
              : 'text-cinnabar border border-gray'
          } w-1/2 text-sm leading-17px rounded-md flex justify-center items-center cursor-pointer h-9`}
          data-qa='option'
          data-key='Previous'
        >
          <Image
            src={`${staticMediaStoreBaseURL}${
              props.disabled
                ? '/icons/left-grey-arrow.svg'
                : '/icons/prev-arrow.svg'
            }`}
            alt='Previous'
            className='me-2'
            height={12}
            width={12}
          />
          <span className='ms-2'> {props.label} </span>
        </div>
      );
    }
    if (props.label === 'Next') {
      return (
        <div
          onClick={handleClick}
          className={`${
            props.disabled
              ? 'text-dim-gray'
              : 'text-cinnabar border border-gray'
          } ms-5 w-1/2 text-sm leading-17px rounded-md flex justify-center items-center cursor-pointer h-9`}
          data-qa='option'
          data-key='Next'
        >
          <span className='me-2'> {props.label} </span>
          <Image
            src={`${staticMediaStoreBaseURL}${
              props.disabled
                ? '/icons/next-arrow-gray.svg'
                : '/icons/next-arrow.svg'
            }`}
            alt='Next'
            className='ms-2'
            height={12}
            width={12}
          />
        </div>
      );
    }
    return '';
  };

  const updateCurrentUrl = () => {
    setCurrentUrl(window.location.href);
  };

  const handleBackButtonClick = () => {
    if (currentUrl) {
      router.push(currentUrl);
    }
  };
  const renderSelectedFilter = (props) => {
    const filter = props.filterId;
    if (!(appliedFilters.length && appliedFilters.includes(filter))) {
      setAppliedFilters([...appliedFilters, filter]);
    }
    return null;
  };

  const [isServerSide, setIsServerSide] = useState(true);
  useEffect(() => {
    setIsServerSide(false);
  }, []);

  return (
    <div
      className='md:hidden'
      key={router.asPath}
    >
      <CategoryMobileHeaderOrganism
        {...{
          categories,
          categorySlug,
          handleModal,
          heading: parentCategoryName,
          paramData: {
            metaDescription,
            metaTitle,
            ogDescription,
            ogImage,
            ogTitle
          },
          parentSlug,
          routeTo: '/',
          showProductSubCategory,
          subCategories,
          subCategorySlug,
          showOnScroll: true
        }}
      />

      {showModal ? (
        <div
          onClick={() => setShowModal(false)}
          className={`fixed z-120 w-full h-full inset-0 top-12 bg-nero bg-opacity-80 flex items-start animated ${
            CloseAnimation ? 'fade-in' : 'fade-out'
          }`}
        >
          <div
            className={`z-50 bg-white w-full relative px-5 transition-all ${
              CloseAnimation ? 'slide-down' : 'slide-up'
            }`}
          >
            <ProductParentCategories categories={parentCategories} />
            <div
              onClick={(e) => {
                handleModal(e);
              }}
              className='border border-gray bg-white rounded-full w-5 h-5 box-border flex justify-center absolute -bottom-2 right-4'
            >
              <Image
                src={`${staticMediaStoreBaseURL}${upIcon}`}
                alt='Up Arrow'
                className='w-full mx-auto max-w-2.5'
                height={10}
                width={10}
              />
            </div>
          </div>
        </div>
      ) : (
        ''
      )}
      {!isServerSide ? (
        <SearchkitProvider searchkit={searchkit}>
          <>
            <SelectedFilters
              mod={'hidden'}
              itemComponent={renderSelectedFilter}
            />
            <div
              id='product-list'
              ref={productListingRef}
              className='scroll-mt-23'
            >
              <HitsStats component={renderProductsCount} />
              <ProductListOrganism size={50} />

              <Pagination
                showNumbers={false}
                pageScope={0}
                showLast={true}
                mod='px-5 py-5'
                listComponent={
                  <Toggle
                    mod='font-Montserrat flex items-center justify-center'
                    itemComponent={renderPagination}
                  />
                }
              />
              <div className='flex flex-col gap-4 mb-16 px-5'>
                {getSerializedRichText(description) && (
                  <Accordion
                    {...{
                      heading: `About ${category.name}`,
                      richText: description
                    }}
                  />
                )}
                {getSerializedRichText(faq) && (
                  <Accordion
                    {...{
                      heading: 'Frequently Asked Questions',
                      richText: faq
                    }}
                  />
                )}
              </div>
            </div>
            <div className='fixed w-full bottom-0 z-10'>
              <ProductFilter
                sortOrderShowHideHandler={handleSortModal}
                filterByShowHideHandler={handleFilterModal}
                filterCount={appliedFilters.length}
              />
            </div>
            <div className={`${!isSorting ? 'hidden' : ''}`}>
              <BottomModal
                paramData={{
                  modalHeight: 'min-h-43.5',
                  label: 'Sort By',
                  setCloseModal: setIsSorting,
                  showHeader: true,
                  handler: () => setIsSorting(false),
                  hideClose: true
                }}
              >
                <div onClick={() => setIsSorting(false)}>
                  <SortingSelector
                    className={'flex flex-col px-5 text-matterhorn'}
                    options={[
                      {
                        label: 'Relevance',
                        field: 'weightage',
                        order: 'desc',
                        defaultOption: true
                      },
                      {
                        label: 'Price: Low to High',
                        field: 'price.sortPrice',
                        order: 'asc'
                      },
                      {
                        label: 'Price: High to Low',
                        field: 'price.sortPrice',
                        order: 'desc'
                      }
                    ]}
                    listComponent={<CheckboxItemList />}
                  />
                </div>
              </BottomModal>
            </div>
          </>
          <div className={`${!isFilter ? 'hidden' : ''}`}>
            <FilterModal
              searchkit={searchkit}
              paramData={{
                modalHeight: 'min-h-full',
                setCloseModal: setIsFilter,
                showHeader: true,
                handler: () => setIsFilter(false),
                handleBackButtonClick,
                clearFilters: setAppliedFilters
              }}
            >
              <ProductFilterModal
                setCloseModal={setIsFilter}
                searchkit={searchkit}
                updateCurrentUrl={updateCurrentUrl}
                priceRange={priceRange}
                setAppliedFilters={setAppliedFilters}
                openTab={openTab}
                setOpenTab={setOpenTab}
              />
            </FilterModal>
          </div>
        </SearchkitProvider>
      ) : (
        <>
          <div
            id='product-list'
            ref={productListingRef}
            className='scroll-mt-23'
          >
            {renderProductsCount({ hitsCount: productsCount })}
            <div
              className='grid grid-cols-1 xs:auto-rows-fr xs:grid-cols-2 lg:grid-cols-3 justify-between gap-y-5 md:gap-y-6
        xs:gap-x-4 md:gap-x-6 px-5 md:px-0 pb-16 md:pb-0 mt-1 md:my-5px mx-0 md:mx-5px'
            >
              {initialProducts?.length &&
                initialProducts.map((product) => (
                  <ProductCard
                    key={product.id}
                    product={product}
                  />
                ))}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default CategoryMobileLayoutTemplate;
