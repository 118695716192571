import { useRouter } from 'next/router';
import { Fragment, useEffect, useRef, useState } from 'react';
import {
  HitsStats,
  Pagination,
  ResetFilters,
  SearchkitManager,
  SearchkitProvider,
  SelectedFilters,
  SortingSelector,
  TermQuery,
  Toggle
} from 'searchkit';
import url from 'url';

import {
  Breadcrumbs,
  PageNotFound,
  RenderResetFilter,
  SelectedFilter
} from 'components/atomic/atoms';
import {
  Accordion,
  CategorySideBarMenu,
  RenderPagination
} from 'components/atomic/molecules';
import { Text } from 'components/atomic/nuclei';
import {
  ProductListOrganism,
  RenderProductCardsOrganism
} from 'components/atomic/organism';
import StandardLayout from 'components/layout/StandardLayout';
import searchKitConfig from 'config/searchKit';
import { getSerializedRichText } from 'helpers/editor';
import { getCategoryListingPageH1Title } from 'services/categories.service';
import { HOST_PORTAL_URL } from 'services/connections.service';

const sortingSelectorOptions = [
  {
    label: 'Relevance',
    field: 'weightage',
    order: 'desc',
    defaultOption: true
  },
  {
    label: 'Price: Low to High',
    field: 'price.sortPrice',
    order: 'asc'
  },
  {
    label: 'Price: High to Low',
    field: 'price.sortPrice',
    order: 'desc'
  }
];

const DescriptionFAQBlock = ({ categoryName, description, faq }) => (
  <div className='flex flex-col gap-4 my-4 mx-20'>
    {getSerializedRichText(description) && (
      <Accordion
        {...{
          heading: `About ${categoryName}`,
          richText: description
        }}
      />
    )}
    {getSerializedRichText(faq) && (
      <Accordion
        {...{ heading: 'Frequently Asked Questions', richText: faq }}
      />
    )}
  </div>
);

const ClientSideProductList = ({
  breadcrumbsInfo,
  categories,
  category,
  categorySlug,
  description,
  faq,
  initialProducts,
  parentCategoryName,
  parentSlug,
  priceRange,
  productListingRef,
  productsCount,
  RenderProductsCount,
  searchkit,
  subCategorySlug
}) => {
  if (productsCount < 1) {
    return (
      <div>
        <PageNotFound
          {...{
            message: 'Product(s) Not Found',
            subMessage1: 'No products found in category.'
          }}
        />
        <DescriptionFAQBlock
          {...{
            categoryName: category.name,
            description,
            faq
          }}
        />
      </div>
    );
  }

  return (
    <div>
      <div
        className='mt-6 z-0 flex px-5 md:px-20 scroll-mt-40'
        id='product-list'
        ref={productListingRef}
      >
        <CategorySideBarMenu
          {...{
            categories,
            categorySlug,
            currentCategory: category,
            parentCategoryName,
            parentSlug,
            priceRange,
            searchkit,
            subCategorySlug
          }}
        />
        <div className='w-full'>
          <Breadcrumbs {...{ breadCrumbs: breadcrumbsInfo }} />
          <SearchkitProvider {...{ searchkit }}>
            <Fragment>
              <HitsStats {...{ component: RenderProductsCount }} />
              <div className='flex space-x-7 mb-4 ms-1'>
                <Text
                  {...{
                    className: 'text-nero text-sm font-medium',
                    content: 'Sort By'
                  }}
                />
                <SortingSelector
                  {...{
                    className:
                      'ms-1 flex space-x-5 list-none text-sm font-medium',
                    listComponent: <Toggle {...{ mod: 'cursor' }} />,
                    options: sortingSelectorOptions
                  }}
                />
              </div>
              <div className='flex'>
                <SelectedFilters
                  {...{
                    itemComponent: SelectedFilter,
                    mod: 'ms-1 flex list-none space-x-4 mb-4'
                  }}
                />
                <ResetFilters
                  {...{
                    component: RenderResetFilter,
                    options: {
                      filter: true,
                      pagination: false,
                      query: true
                    }
                  }}
                />
              </div>
              <ProductListOrganism
                {...{
                  initialProducts,
                  size: 15
                }}
              />
              <Pagination
                {...{
                  listComponent: (
                    <Toggle
                      {...{
                        itemComponent: RenderPagination,
                        mod: 'font-Montserrat font-medium hidden md:flex flex-row items-center'
                      }}
                    />
                  ),
                  mod: 'mt-6 py-4 border-t border-gray flex items-center justify-center',
                  pageScope: 3,
                  showLast: true,
                  showNumbers: true
                }}
              />
            </Fragment>
          </SearchkitProvider>
        </div>
      </div>
      <DescriptionFAQBlock
        {...{
          categoryName: category.name,
          description,
          faq
        }}
      />
    </div>
  );
};
const ServerSideList = ({
  breadcrumbsInfo,
  categoryName,
  description,
  faq,
  initialProducts,
  productListingRef,
  productsCount,
  RenderProductsCount
}) => (
  <div
    className='mt-6 z-0 flex px-5 md:px-20 scroll-mt-40'
    id='product-list'
    ref={productListingRef}
  >
    <div className='w-full'>
      <Breadcrumbs {...{ breadCrumbs: breadcrumbsInfo }} />
      <Fragment>
        <RenderProductsCount {...{ hitsCount: productsCount }} />
        <RenderProductCardsOrganism {...initialProducts} />
        <DescriptionFAQBlock
          {...{
            categoryName,
            description,
            faq
          }}
        />
      </Fragment>
    </div>
  </div>
);

const CategoryDesktopLayoutTemplate = ({
  breadcrumbsInfo,
  categories,
  category,
  categorySeoDetails,
  categorySlug,
  initialProducts,
  parentCategories,
  parentCategoryName,
  parentSlug,
  priceRange,
  productsCount,
  subCategorySlug
}) => {
  const router = useRouter();
  const productListingRef = useRef(null);
  useEffect(() => {
    productListingRef?.current?.scrollIntoView();
  }, []);
  const { pathname } = url.parse(HOST_PORTAL_URL + router.asPath, true);

  const {
    canonicalPath,
    description,
    faq,
    metaDescription,
    metaTitle,
    ogDescription,
    ogImage,
    ogTitle
  } = categorySeoDetails;

  const { name } = category;

  const [showHeaderEventList, setShowHeaderEventList] = useState(true);

  let searchKitTermQueries = [];
  if (subCategorySlug) {
    searchKitTermQueries = [TermQuery('assignedToCategories', subCategorySlug)];
  } else if (categorySlug) {
    searchKitTermQueries = [TermQuery('assignedToCategories', categorySlug)];
  } else {
    searchKitTermQueries = [TermQuery('assignedToCategories', parentSlug)];
  }
  const searchkit = new SearchkitManager(
    searchKitConfig.searchKitProductListAPIEndPoint
  );
  searchkit.addDefaultQuery((query) =>
    query.addQuery({
      bool: {
        should: searchKitTermQueries
      }
    })
  );
  searchkit.setQueryProcessor((plainQueryObject) => plainQueryObject);

  useEffect(() => {
    setShowHeaderEventList(pathname !== '/categories/food-and-beverages');
  }, [router.asPath, pathname]);
  const [isServerSide, setIsServerSide] = useState(true);

  useEffect(() => {
    setIsServerSide(false);
  }, []);

  const RenderProductsCount = ({ hitsCount }) => (
    <div
      className='my-4 ms-1'
      data-qa='hits-stats'
    >
      <Text
        {...{
          className:
            'inline-block font-medium text-base font-Montserrat text-nero',
          content: getCategoryListingPageH1Title(category),
          HtmlTag: 'h1'
        }}
      />
      <Text
        {...{
          className: 'font-normal text-base font-Montserrat text-dim-gray',
          content: ` - ${hitsCount} products`,
          HtmlTag: 'span'
        }}
      />
    </div>
  );

  return (
    <Fragment>
      <StandardLayout
        key={router.asPath}
        {...{
          headParameters: {
            canonicalPath,
            categories: parentCategories,
            categoriesWithSubCategories: categories,
            heading: 'Categories',
            metaDescription: metaDescription || description || name,
            metaTitle: metaTitle || name,
            ogDescription: ogDescription || description || name,
            ogImage,
            ogTitle: ogTitle || name,
            parentCategories,
            parentSlug,
            showFooter: true,
            showHeaderEventList
          }
        }}
      >
        {isServerSide ? (
          <ServerSideList
            {...{
              breadcrumbsInfo,
              categoryName: category.name,
              description,
              faq,
              initialProducts,
              productListingRef,
              productsCount,
              RenderProductsCount
            }}
          />
        ) : (
          <ClientSideProductList
            {...{
              breadcrumbsInfo,
              categories,
              category,
              categorySlug,
              description,
              faq,
              initialProducts,
              parentCategoryName,
              parentSlug,
              priceRange,
              productListingRef,
              productsCount,
              RenderProductsCount,
              searchkit,
              subCategorySlug
            }}
          />
        )}
      </StandardLayout>
    </Fragment>
  );
};

export default CategoryDesktopLayoutTemplate;
