import { NextSeo } from 'next-seo';
import { useRouter } from 'next/router';

import {
  Footer,
  LdJSONSchemaScriptTag,
  Loader,
  PageNotFound,
  ProgressBar
} from 'components/atomic/atoms';
import { ToastContainer } from 'components/atomic/molecules';
import { HeaderOrganism } from 'components/atomic/organism';
import { mediaStoreBaseURL, staticMediaStoreBaseURL } from 'config/common';
import { HOST_PORTAL_URL } from 'services/connections.service';

const StandardLayout = ({
  children,
  headParameters = {},
  loading,
  pageDataFound = true,
  pageNotFound: { description, notFoundImage, title } = {},
  setShowToast,
  showHeader = true,
  showLoader = true,
  showToast = { message: '', show: false, status: false }
}) => {
  const {
    canonicalPath,
    categoriesWithSubCategories,
    doNotShowWhatsApp,
    eventJourney,
    heading,
    ldJson,
    metaDescription,
    metaTitle,
    ogDescription,
    ogImage,
    ogTitle,
    parentCategories,
    parentSlug,
    showCartView,
    showFooter,
    showHeaderEventList,
    showSearchIcon
  } = headParameters;

  const router = useRouter();
  const direction = 'ltr';

  if (loading) {
    return <Loader />;
  }

  const ogImageSrc = ogImage
    ? `${mediaStoreBaseURL}/${ogImage}`
    : `${staticMediaStoreBaseURL}/icons/hafla-icon.png`;
  const canonicalPathURL = canonicalPath
    ? `${HOST_PORTAL_URL}/${canonicalPath}`
    : null;

  return (
    <>
      <NextSeo
        canonical={canonicalPathURL}
        description={metaDescription}
        openGraph={{
          description: ogDescription,
          images: [{ url: ogImageSrc }],
          title: ogTitle,
          url: `${HOST_PORTAL_URL}${router.asPath}`
        }}
        title={metaTitle}
      />
      {ldJson && <LdJSONSchemaScriptTag ldJson={ldJson} />}
      <ToastContainer {...{ showToast, setShowToast }} />
      {pageDataFound ? (
        <div className='flex flex-col justify-between h-screen'>
          <div dir={direction}>
            {showLoader && <ProgressBar />}
            {showHeader && (
              <HeaderOrganism
                {...{
                  categories: parentCategories,
                  categoriesWithSubCategories,
                  doNotShowWhatsApp,
                  eventJourney,
                  heading,
                  parentSlug,
                  showCartView,
                  showHeaderEventList,
                  showSearchIcon
                }}
              />
            )}
            <main>{children}</main>
          </div>
          {showFooter ? <Footer /> : ''}
          {
            // TODO: if showHeader is outside, showFooter should have been outside as well
          }
        </div>
      ) : (
        <PageNotFound
          {...{
            description,
            notFoundImage,
            title
          }}
        />
      )}
    </>
  );
};

export default StandardLayout;
